import tunes from "./tunes";
import sets from "./sets";
import ui from "./ui";
import session from "./session";

const index = {
  ui,
  session,
  tunes,
  sets
};

export default index;
